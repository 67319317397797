<template>
    <div class="no-data" :style="{ width: width }">
        <div class="dashboard-no-data-img" :style="{ height: imgHeight }" />
        <div v-if="message" class="message" v-text="message" />
    </div>
</template>
<script>
export default {
    name: 'DashboardNoData',
    props: {
        message: {
            type: String
        },
        width: {
            type: String,
            default: '100%'
        },
        imgHeight: {
            type: String,
            default: '80px'
        }
    }
}
</script>

<style lang="scss" scoped>
.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    pointer-events: none;

    .dashboard-no-data-img {
        width: 100%;
        height: 80px;
        margin-bottom: 10px;
    }
    .message {
        width: 100%;
        color: $ninth-grey;
        font-size: 14px;
        text-align: center;
    }
}
</style>
